@import "base/modernize-vars";

$footer-link-color: #878787;

footer#modernize-footer {
  margin-top: auto;
  background-color: $green-dark;
  color: $font-grey;
  display: flex;
  flex-direction: column;
  padding: 24px;
  clear: both;
  width: 100%;
  z-index: 2;

  #footer-inner {
    width: 100%;
    margin: 0 auto;
  }

  .social-links a,
  .main-links a {
    color: $font-grey;
    font-weight: bold;

    &:hover {
      color: $grey-light;
    }
  }
  ul.main-links {
    margin-bottom: 0.3rem;
    margin-left: 0;

    li {
      display: inline;
      padding: 8px;

      a {
        white-space: nowrap;
      }

      &.active a {
        color: scale-color($footer-link-color, $lightness: -28%);
      }

      &.active a {
        font-weight: 500;
      }
    }
  }

  .social-links {
    margin-top: 0.4rem;
    list-style: none;

    li {
      padding: 0 5px;
    }
  }

  #language-selector {
    display: flex;
    align-items: center;
    gap: 8px;
    border: solid 1px $font-grey;
    border-radius: 4px;
    color: $font-grey;
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    padding: 10px 14px;
    margin: 0;
    cursor: pointer;

    i {
      font-size: 8px;
      margin: 0;
      transform: rotate(0deg);
    }

    &:hover {
      color: $blue-gray-200;
      border-color: $blue-gray-200;
    }
  }
  #language-selector[aria-expanded="true"] i.icon-caret-down {
    transform: rotate(180deg);
  }

  #language-dropdown {
    width: 150px;

    div.highlight {
      background: $blue-gray-100;
    }
    div {
      justify-content: center;
      margin-right: 0 !important;

      a {
        text-align: left;
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 400;
        padding: 12px 16px;
      }
      a.selected {
        color: $blue-gray-900;
        font-weight: 600;
      }
      a:hover {
        color: $font-black;
      }

      i.icon-check {
        font-size: 14px;

        &.visibility-hidden {
          visibility: hidden;
        }
      }
    }
    div:hover {
      background: $blue-gray-100;

      a {
        color: $blue-gray-800;
      }
    }
  }

  #footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      padding: 0;
      margin-right: 40px;

      a {
        font-size: 14px;
      }
    }
  }

  hr {
    border: 1px solid $green-med;
    margin: 24px 0;
  }

  #footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      font-size: 16px;
    }

    .social-links {
      display: flex;

      li {
        padding: 0 16px;
      }
    }

    #copyright {
      font-size: 14px;
    }

    .social-links li:first-child {
      border-right: 1px solid $green-med;
    }

    .social-links li:last-child {
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  footer#modernize-footer {
    padding: 24px 24px;

    #footer-top {
      display: flex;
      justify-content: space-between;

      .main-links {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
          text-align: center;
          margin: 0;
          margin-bottom: 10px;

          a {
            white-space: normal;
          }
        }
      }
    }
  }
}
